import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "~/components/loader";
import { Button } from "~/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { toast } from "~/components/ui/sonner-toast";
import { api } from "~/utils/api";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { LeadSource } from "@prisma/client";
import { type FormattedLead } from "~/server/api/routers/lead";
import { Textarea } from "~/components/ui/textarea";

export const createLeadInput = z.object({
  name: z.string().optional(),
  email: z.string().email(),
  // 10 digit phone number
  contactNumber: z.string().optional(),
  company: z.string().optional(),
  message: z.string().optional(),
  source: z.nativeEnum(LeadSource).optional(),
  campaign: z.string().optional(),
});

export const updateLeadInput = z.object({
  id: z.string(),
  ...createLeadInput.shape,
});

const createLeadInputWithMessage = z.object({
  ...createLeadInput.shape,
  message: z
    .string()
    .min(3, { message: "Message must be at least 3 characters long" }),
});

export function LeadCreateForm({
  onSuccess,
  campaign,
  source,
  prefillData,
  messageRequired = false,
}: {
  onSuccess?: () => Promise<void> | void;
  campaign?: string;
  source?: LeadSource;
  prefillData?: {
    name?: string;
    email?: string;
  };
  messageRequired?: boolean;
}) {
  const createLeadInputToUse = messageRequired
    ? createLeadInputWithMessage
    : createLeadInput;
  const ctx = api.useContext();
  const createMutation = api.lead.create.useMutation({
    onSuccess: (data) => {
      if (!data) {
        toast({
          title: "Error",
          description: "Something went wrong.",
          variant: "destructive",
        });
        return;
      }
      toast({
        title: "Thanks! 🎉",
        description: "Your details have been saved.",
        variant: "default",
      });
      void ctx.lead.invalidate();
      if (onSuccess) {
        void onSuccess();
      }
      form.reset();
    },
    onError: (error) => {
      if (error.message.includes("already exists")) {
        toast({
          title: "Thanks! 🎉",
          description: "We already have your details.",
          variant: "default",
        });
        form.reset();
        return;
      }
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
      form.reset();
      return;
    },
  });

  type formSchema = z.infer<typeof createLeadInputToUse>;

  const onSubmit = async (data: formSchema) => {
    // console.log("LeadCreateForm.onSubmit.data", data);
    if (data.contactNumber === "") {
      delete data.contactNumber;
    }
    await createMutation.mutateAsync({
      ...data,
      campaign: campaign,
      source: source ?? (campaign ? LeadSource.CAMPAIGN : undefined),
    });
  };

  const form = useForm<formSchema>({
    resolver: zodResolver(createLeadInputToUse),
    defaultValues: {
      email: prefillData?.email ?? "",
      name: prefillData?.name ?? "",
      contactNumber: "",
      company: "",
      message: "",
    },
  });

  useEffect(() => {
    // to auto focus on message field if prefillData is provided
    if (prefillData?.name && prefillData?.email) {
      form.setFocus("message");
    }
  }, []);

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void form.handleSubmit(onSubmit)(e);
          }}
          className="space-y-4 md:space-y-8"
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Name
                  <span className="text-xs font-normal text-muted-foreground">
                    {" "}
                    (optional)
                  </span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Mark Scout" {...field} />
                </FormControl>
                {/* <FormDescription>Your full name.</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="mark.s@lumon.com" {...field} />
                </FormControl>
                {/* <FormDescription>Your email address.</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contactNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Contact Number
                  <span className="text-xs font-normal text-muted-foreground">
                    {" "}
                    (optional)
                  </span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="9999999999" {...field} />
                </FormControl>
                {/* <FormDescription>Your contact number.</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="company"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Company
                  <span className="text-xs font-normal text-muted-foreground">
                    {" "}
                    (optional)
                  </span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Lumon" {...field} />
                </FormControl>
                {/* <FormDescription>Your company name.</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  {/* <Input placeholder="I am interested in..." {...field} /> */}
                  <Textarea placeholder="I am interested in..." {...field} />
                </FormControl>
                {/* <FormDescription>Your message.</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={form.formState.isSubmitting} type="submit">
            {form.formState.isSubmitting && <Loader className="mr-2" />}
            {form.formState.isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </form>
      </Form>
    </>
  );
}

export function LeadUpdateForm({
  lead,
  onSuccess,
}: {
  lead: FormattedLead;
  onSuccess?: () => Promise<void> | void;
}) {
  const ctx = api.useContext();
  const updateMutation = api.lead.update.useMutation({
    onSuccess: async () => {
      toast({
        title: "Lead updated",
        description: "Your lead has been updated.",
        variant: "default",
      });
      await ctx.lead.invalidate();
      if (onSuccess) {
        void onSuccess();
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message ?? "Unexpected error",
        variant: "destructive",
      });
    },
  });

  type formSchema = z.infer<typeof updateLeadInput>;

  const onSubmit = async (data: formSchema) => {
    // console.log("LeadUpdateForm.onSubmit.data", data);
    await updateMutation.mutateAsync({
      ...data,
      source: data.source,
      campaign: data.campaign ?? undefined,
    });
  };

  const form = useForm<formSchema>({
    resolver: zodResolver(updateLeadInput),
    defaultValues: {
      id: lead.id,
      email: lead.email,
      name: lead.name ?? "",
      contactNumber: lead.contactNumber ?? "",
      company: lead.company ?? "",
      message: lead.message ?? "",
      source: lead.source,
      campaign: lead.campaign ?? undefined,
    },
  });

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            void form.handleSubmit(onSubmit)(e);
          }}
          className="space-y-8"
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Mark Scout" {...field} />
                </FormControl>
                <FormDescription>Your full name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="mark.s@lumon.com" {...field} />
                </FormControl>
                <FormDescription>Your email address.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contactNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Contact Number</FormLabel>
                <FormControl>
                  <Input placeholder="9999999999" {...field} />
                </FormControl>
                <FormDescription>Your contact number.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="company"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company</FormLabel>
                <FormControl>
                  <Input placeholder="Lumon" {...field} />
                </FormControl>
                <FormDescription>Your company name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Input placeholder="I am interested in..." {...field} />
                </FormControl>
                <FormDescription>Your message.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <input type="hidden" {...form.register("id")} />
          <Button disabled={form.formState.isSubmitting} type="submit">
            {form.formState.isSubmitting && <Loader className="mr-2" />}
            {form.formState.isSubmitting ? "Updating..." : "Update"}
          </Button>
        </form>
      </Form>
    </>
  );
}

export function LeadDeleteForm({
  leadId,
  onSuccess,
  onCancel,
}: {
  leadId: string;
  onSuccess?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}) {
  const ctx = api.useContext();
  const deleteMutation = api.lead.delete.useMutation({
    onSuccess: async () => {
      toast({
        title: "Lead deleted",
        description: "Your lead has been deleted.",
        variant: "default",
      });
      await ctx.lead.invalidate();
      if (onSuccess) {
        void onSuccess();
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message ?? "Unexpected error",
        variant: "destructive",
      });
    },
  });

  const onConfirm = async () => {
    await deleteMutation.mutateAsync({ id: leadId });
  };

  const onCancelClick = async () => {
    if (onCancel) {
      await onCancel();
    }
  };

  return (
    <>
      <div className="flex justify-end space-x-2">
        <Button
          size="sm"
          variant="outline"
          onClick={() => void onCancelClick()}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          variant="destructive"
          onClick={() => void onConfirm()}
          disabled={deleteMutation.isLoading}
        >
          Delete
        </Button>
      </div>
    </>
  );
}
